"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = void 0;
exports.environment = {
    firebase: {
        apiKey: "AIzaSyA5MdcaagcuRAfgQ2F8ACHCSkhzY5njqbQ",
        authDomain: "cya-app-fa34a.firebaseapp.com",
        projectId: "cya-app-fa34a",
        storageBucket: "cya-app-fa34a.appspot.com",
        messagingSenderId: "845984776219",
        appId: "1:845984776219:web:c94c89f64c80ba9074b8be",
        measurementId: "G-V1C09XL7M6"
    },
    openAI: {
        apiKey: "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"
    },
};
