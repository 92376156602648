"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonTitleStyle = exports.ButtonStyle = exports.InputStyle = exports.BaseShadow = exports.CurrentTheme = exports.LightTheme = void 0;
exports.LightTheme = {
    ContainerColor: '#000009',
    ContentBoxColor: '#464F5150',
    TabBarIconColor: '#464F5150',
    TextColor: '#EEF0F2',
    TextColorInfo: '#DEFFF2',
    TextErrorColor: 'red',
    ButtonColor: '#0FF4C6',
    ShadowColor: "#D68FD6"
};
exports.CurrentTheme = exports.LightTheme;
exports.BaseShadow = {
    shadowColor: exports.CurrentTheme.ShadowColor,
    shadowOffset: {
        width: 2,
        height: 4,
    },
    shadowOpacity: .4,
    shadowRadius: 3,
};
exports.InputStyle = {
    marginTop: "4px",
    marginBottom: "16px",
    padding: "8px",
    height: "44px",
    borderColor: "#121212",
    borderWidth: 1,
    backgroundColor: exports.CurrentTheme.ContentBoxColor,
    borderRadius: 4,
    color: "white",
    fontSize: 16
};
exports.ButtonStyle = {
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 8,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: exports.CurrentTheme.ButtonColor,
    height: 44,
    ...exports.BaseShadow
};
exports.ButtonTitleStyle = {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: 'black',
};
