"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootStoreContext = exports.RootStore = void 0;
const react_1 = require("react");
const chat_store_1 = require("./chat-store");
class RootStore {
    chatStore = new chat_store_1.ChatStore(this);
    constructor() {
    }
}
exports.RootStore = RootStore;
exports.RootStoreContext = (0, react_1.createContext)(new RootStore());
